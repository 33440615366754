<template>
  <fire-alarm />
</template>

<script>
import FireAlarm from "../../components/solutions/intelligent-security/FireAlarm.vue";
export default {
  components: { FireAlarm },
};
</script>
