<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Fire Alarm Panels</h2>
      <p class="desc my-3">
        For the addressable fire alarm systems, it is a remarkable approach that
        delivers an optimum novice solution. Its main focus is on installations
        at a low-level, where usually the cost of using these addressable
        systems has reportedly been considered prohibitive.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/fire-alarm/intelligent-security-solutionsimg-4.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-fire-alarm-section ",
};
</script>
