<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Fire Alarm Detectors</h2>
      <p class="desc my-3">
        Before determining the kind of detector to use in any field, it is
        essential to acknowledge the fact that the purpose of the detector is to
        distinguish between an actual fire and other normal circumstances that
        exist therein.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/fire-alarm/intelligent-security-solutionsimg-1.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-fire-alarm-section ",
};
</script>
