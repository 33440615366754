<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Manual Call Points</h2>
      <p class="desc my-3">
        The device which allows an individual to trigger the alarm due to the
        occurrence of any sort of fire by putting pressure on a fragile
        component is referred to as ‘Manual Call Point’.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/fire-alarm/intelligent-security-solutionsimg-2.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-fire-alarm-section ",
};
</script>
