<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Zeta GSM Communicator</h2>
      <p class="desc my-3">
        Whenever there is some sort of fault or alarm, the Zeta GSM communicator
        interface enables a fire alarm panel to convey an SMS. When the operator
        receives a standard Mobile Phone SIM card, it is then programmed with
        the phone numbers as well as alarm information through the use of a
        Personal Computer.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/fire-alarm/intelligent-security-solutionsimg-5.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "Fivth-fire-alarm-section ",
};
</script>
