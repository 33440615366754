<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Fire Alarm Sounder & Flasher</h2>
      <p class="desc my-3">
        Is it appropriate to use a huge horn sounder for various applications?
        Through the Zeta Addressable Protocol, it can be utilized on any sort of
        panel. It is offered in both transparent red as a collective sounder
        flasher and in pure white or red as a sounder only and is convenient for
        both outdoor as well as indoor use.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/fire-alarm/intelligent-security-solutionsimg-3.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-fire-alarm-section ",
};
</script>
